import type { Validator } from "@/js/utils/validators";
import type { FocusEvent, ReactElement, ReactNode } from "react";

import { Tooltip } from "antd";
import pick from "lodash/pick";
import { cloneElement, forwardRef, useImperativeHandle, useRef } from "react";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";
import { notEmpty } from "@/js/utils/validators";

export type FormFieldHandle = {
  focus?: () => void;
  validate: () => string[];
};

export interface FormFieldProps {
  className?: string;
  children: ReactElement;
  /** Not used in this component, but by the FormFieldRow to give the element the correct width */
  gridWidth?: number;
  /** This will render as a <label> element, clickable to focus the field */
  label?: string;
  /** Additional info about the form field displayed as a tooltip. Possibly deprecrated in favor of note */
  info?: ReactNode;
  /** Use for additional info; preferred over info prop */
  note?: ReactNode;
  /** Usually spine-case, this is the key associated with the input value */
  name: string;
  /** This will come from FormContext, to determine when to show any validation errors */
  onBlur?: (evt: FocusEvent) => void;
  /** When true, this component auto-adds the notEmpty() validator, as well as a label asterisk */
  required?: boolean;
  /** When true, the first validation error, if present, will render below the field */
  shouldValidate?: boolean;
  /** Used by the FormFieldRow component to pass grid column info to the form field */
  style?: any;
  /** List of validations for the field value to pass, in order */
  validators?: ReturnType<Validator>[];
  /**
   * This component uses the value to validate it. It then passes it down the to the form field.
   * Note that we only need to pass it to this parent component, not to the form field child itself.
   * The array types are used for multiselect components only.
   */
  value: boolean | string | number | string[] | number[];
}

const FormField = forwardRef<FormFieldHandle, FormFieldProps>(
  ({ validators = [], required = true, style, ...props }, ref) => {
    // id needs to stay constant
    const idRef = useRef(props.name);
    const inputRef = useRef<HTMLElement>();
    const _validators = required ? [notEmpty()].concat(validators) : validators;

    /**
     * Returns a list of error messages for all the validations this field did not pass, or an
     * empty list if there are none/none that failed.
     */
    const validate = (): string[] =>
      _validators.reduce((memo, validator) => {
        const { isValid, errorMessage } = validator(props.value, props.label || props.name);

        return memo.concat(!isValid ? errorMessage : []);
      }, [] as string[]);

    const errorMessage = props.shouldValidate ? validate()[0] : null;

    /**
     * This handle allows the parent Form component to validate its FormField children,
     * which register themselves in its ref via the forwarded ref.
     */
    useImperativeHandle(ref, () => ({
      ...(typeof inputRef.current?.focus === "function" ?
        { focus: () => inputRef.current.focus() }
      : {}),
      validate,
    }));

    return (
      <div className={classnames("FormField", props.name, props.className)} style={style}>
        {props.label ?
          <label htmlFor={idRef.current}>
            {props.label}
            {!required ?
              <span className="optional" aria-hidden>
                (Optional)
              </span>
            : null}
            {props.info ?
              <Tooltip color="#009088" title={props.info}>
                {/**
                 * This extra span element is to take the mouse events passed by the ant tooltip.
                 * We can remove it once we create our own Tooltip component.
                 */}
                <span>
                  <SvgIcon name="info-circle" />
                </span>
              </Tooltip>
            : null}
            {props.note ?
              <small aria-hidden>{props.note}</small>
            : null}
          </label>
        : null}
        <span>
          {cloneElement(props.children, {
            ref: inputRef,
            id: idRef.current,
            invalid: !!errorMessage,
            ...pick(props, "disabled", "name", "value", "onBlur"),
            // sometimes we still need to pass a transformed value to a child,
            // for example a date object in the date picker
            value: props.children.props?.value ?? props.value,
          })}
        </span>
        {errorMessage ?
          <small className="error">{errorMessage}</small>
        : null}
      </div>
    );
  },
);

export default FormField;
