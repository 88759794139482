type ValidationResult = {
  isValid: boolean;
  errorMessage: string;
};
export type Validator = (...args: any[]) => (value: any, name: string) => ValidationResult;

/**
 * This module is for the validators used with form fields. Add them here as more and more
 * of them come up!
 */
export const notEmpty: Validator =
  (message = "") =>
  (value, name) => ({
    isValid: !!value,
    errorMessage: message || `${name} cannot be blank`,
  });

export const validEmail: Validator =
  (message = "") =>
  (value, name) => ({
    isValid: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value),
    errorMessage: message || "Please enter a valid email",
  });

export const minLength: Validator = (length: number) => (value, name) => ({
  isValid: value.length >= length,
  errorMessage: `${name} must be at least ${length} characters`,
});

export const oneLowercase: Validator = () => (value, name) => ({
  isValid: /[a-z]+/.test(value),
  errorMessage: `${name} must contain at least one lowercase letter`,
});

export const oneUppercase: Validator = () => (value, name) => ({
  isValid: /[A-Z]+/.test(value),
  errorMessage: `${name} must contain at least one uppercase letter`,
});

export const oneNumber: Validator = () => (value, name) => ({
  isValid: /[\d]+/.test(value),
  errorMessage: `${name} must contain at least one number`,
});

export const custom: Validator =
  (predicate: (val: any) => boolean, message: string) => (value, name) => ({
    isValid: !!predicate(value),
    errorMessage: message,
  });

export const isInteger =
  (
    bounds?: { min?: number; max?: number },
    customErrorMessage?: (value: string, name: string) => string,
  ) =>
  (value: string, name: string) => {
    // Adjust the regex to validate the comma placement
    if (!/^(\d{1,3}(,\d{3})*|\d+)$/.test(value)) {
      return {
        isValid: false,
        errorMessage:
          customErrorMessage ? customErrorMessage(value, name) : `${name} is not a valid number`,
      };
    }

    const sanitizedValue = value.replace(/,/g, "");
    const num = parseInt(sanitizedValue, 10);
    const lowerBoundCheck = bounds?.min !== undefined ? num >= bounds.min : true;
    const upperBoundCheck = bounds?.max !== undefined ? num <= bounds.max : true;

    const defaultErrorMessage =
      `${name} must be an integer` +
      (bounds?.min !== undefined ? ` greater than or equal to ${bounds.min}` : "") +
      (bounds?.max !== undefined ? ` and less than or equal to ${bounds.max}` : "");

    return {
      isValid: !isNaN(num) && lowerBoundCheck && upperBoundCheck,
      errorMessage: customErrorMessage ? customErrorMessage(value, name) : defaultErrorMessage,
    };
  };

export const isDecimal =
  (
    bounds?: { min?: number; max?: number; precision?: number },
    customErrorMessage?: (value: string, name: string) => string,
    requireCommas: boolean = true,
  ) =>
  (value: string, name: string) => {
    // If commas are required, validate with the comma regex, otherwise allow numbers with or without properly placed commas
    const regexFormat =
      requireCommas ? /^-?\d{1,3}(,\d{3})*(\.\d+)?$/ : /^-?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/;

    if (!regexFormat.test(value)) {
      return {
        isValid: false,
        errorMessage:
          customErrorMessage ?
            customErrorMessage(value, name)
          : `${name} is not a valid decimal number`,
      };
    }

    // If precision is specified, check it
    if (bounds?.precision !== undefined) {
      const regexPrecision = new RegExp(`^-?\\d*(\\.\\d{0,${bounds.precision}})?$`);

      if (!regexPrecision.test(value.replace(/,/g, ""))) {
        return {
          isValid: false,
          errorMessage: `${name} must be a decimal number with up to ${bounds.precision} decimal places`,
        };
      }
    }

    // Remove commas for numeric validation
    const sanitizedValue = value.replace(/,/g, "");
    const num = parseFloat(sanitizedValue);
    const lowerBoundCheck = bounds?.min !== undefined ? num >= bounds.min : true;
    const upperBoundCheck = bounds?.max !== undefined ? num <= bounds.max : true;

    const defaultErrorMessage =
      `${name} must be a decimal number` +
      (bounds?.min !== undefined ? ` greater than or equal to ${bounds.min}` : "") +
      (bounds?.max !== undefined ? ` and less than or equal to ${bounds.max}` : "") +
      (bounds?.precision !== undefined ? ` with up to ${bounds.precision} decimal places` : "");

    return {
      isValid: !isNaN(num) && lowerBoundCheck && upperBoundCheck,
      errorMessage: customErrorMessage ? customErrorMessage(value, name) : defaultErrorMessage,
    };
  };
